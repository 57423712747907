<script>

  // This component is no longer used, except for its single string.
  // TODO: remove this component and migrate its string.

  export default {
    name: 'LessonResourceViewer',
    $trs: {
      /* eslint-disable kolibri/vue-no-unused-translations */
      nextInLesson: {
        message: 'Next in lesson',
        context: 'Refers to the next learning resource in a lesson.',
      },
    },
  };

</script>
