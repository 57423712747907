var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.position === 'embedded' ? 'side-panel' : '',style:({
    color: _vm.$themeTokens.text,
    backgroundColor: _vm.$themeTokens.surface,
    width: _vm.width,
  })},[(_vm.topics && _vm.topics.length && _vm.topicsListDisplayed)?_c('div',[_vm._l((_vm.topics),function(t){return _c('div',{key:t.id},[_c('KRouterLink',{ref:"folders",refInFor:true,staticClass:"side-panel-folder-link",attrs:{"text":t.title,"appearanceOverrides":{ color: _vm.$themeTokens.text },"to":_vm.genContentLink(t.id)}})],1)}),(_vm.more && !_vm.topicsLoading)?_c('KButton',{attrs:{"appearance":"basic-link"},on:{"click":function($event){return _vm.$emit('loadMoreTopics')}}},[_vm._v("\n      "+_vm._s(_vm.coreString('viewMoreAction'))+"\n    ")]):_vm._e(),(_vm.topicsLoading)?_c('KCircularLoader'):_vm._e()],2):_c('div',[_c('h2',{staticClass:"title"},[_vm._v("\n      "+_vm._s(_vm.$tr('keywords'))+"\n    ")]),_c('SearchBox',{key:"channel-search",ref:"searchBox",attrs:{"placeholder":"findSomethingToLearn","value":_vm.value.keywords || ''},on:{"change":function (val) { return _vm.$emit('input', Object.assign({}, _vm.value, {keywords: val})); }}}),(Object.keys(_vm.libraryCategoriesList).length)?_c('div',[_c('h2',{staticClass:"section title"},[_vm._v("\n        "+_vm._s(_vm.$tr('categories'))+"\n      ")]),_vm._l((_vm.libraryCategoriesList),function(category,key){return _c('div',{key:category,staticClass:"category-list-item",attrs:{"span":"4"}},[_c('KButton',{attrs:{"text":_vm.coreString(key),"appearance":"flat-button","appearanceOverrides":_vm.isKeyActive(key)
            ? Object.assign({}, _vm.categoryListItemStyles, _vm.categoryListItemActiveStyles)
            : _vm.categoryListItemStyles,"disabled":_vm.availableRootCategories &&
            !_vm.availableRootCategories[key] &&
            !_vm.isKeyActive(key),"iconAfter":_vm.hasNestedCategories(category) ? 'chevronRight' : null},on:{"click":function($event){return _vm.$emit('currentCategory', category)}}})],1)}),_c('div',{staticClass:"category-list-item",attrs:{"span":"4"}},[_c('KButton',{attrs:{"text":_vm.coreString('None of the above'),"appearance":"flat-button","appearanceOverrides":_vm.isKeyActive('no_categories')
            ? Object.assign({}, _vm.categoryListItemStyles, _vm.categoryListItemActiveStyles)
            : _vm.categoryListItemStyles},on:{"click":_vm.noCategories}})],1)],2):_vm._e(),_c('ActivityButtonsGroup',{staticClass:"section",attrs:{"availableLabels":_vm.availableLabels,"activeButtons":_vm.activeActivityButtons},on:{"input":_vm.handleActivity}}),_c('SelectGroup',{staticClass:"section",attrs:{"availableLabels":_vm.availableLabels,"showChannels":_vm.showChannels},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}),(Object.keys(_vm.resourcesNeededList).length)?_c('div',{staticClass:"section"},[_c('h2',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.coreString('showResources'))+"\n      ")]),_vm._l((_vm.resourcesNeededList),function(val,activity){return _c('div',{key:activity,attrs:{"span":"4","alignment":"center"}},[_c('KCheckbox',{attrs:{"checked":_vm.value.learner_needs[val],"label":_vm.coreString(activity),"disabled":_vm.availableNeeds && !_vm.availableNeeds[val]},on:{"change":function($event){return _vm.handleNeed(val)}}})],1)})],2):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }